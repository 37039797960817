<div class="menu-bar">
  <div class="content">
    <nav>
      <ul>
        <ng-template
          #recursiveSubMenus
          let-subMenuData="subMenuData"
          let-parentMenuTextKey="parentMenuTextKey"
        >
          <li
            *ngFor="let menu of subMenuData"
            [ngClass]="{ submenu: menu.subMenus?.length }"
          >
            <a
              *ngIf="menu.activityData"
              (click)="
              openActivity(
                  menu.activityData.year,
                  menu.activityData.id
                )
              "
              >{{ menu.menu | translate }}</a
            >
            <a
              *ngIf="menu.sponsorData"
              (click)="openSponsor(menu.sponsorData.year, menu.sponsorData.id)"
              >{{ menu.menu }}</a
            >
            <a
              *ngIf="menu.officeBearers && menu.officeBearers.id === 0"
              (click)="openOfficeBearers(menu.officeBearers.year, 0)"
              >{{ menu.menu | translate }}</a
            >
            <a
              *ngIf="menu.officeBearers && menu.officeBearers.id !== 0"
              (click)="
                openOfficeBearers(
                  menu.officeBearers.year,
                  menu.officeBearers.id
                )
              "
              >{{ menu.officeBearers.role | translate }} -
              {{ menu.officeBearers.name | translate }}</a
            >

            <a
              *ngIf="menu.committee"
              (click)="openCommittee(menu.committee.year)"
              >{{ menu.menu | translate }}</a
            >

            <a *ngIf="menu.file" [href]="menu.file" translate>{{
              parentMenuTextKey + "." + menu.menu + ".name"
            }}</a>

            <a
              *ngIf="menu.project"
              translate
              (click)="openProject(menu.project.type, menu.project.id)"
              [innerHtml]="menu.menu | translate"
            ></a>

            <a
              *ngIf="
                !menu.activityData &&
                !menu.sponsorData &&
                !menu.officeBearers &&
                !menu.committee &&
                !menu.file &&
                !menu.project
              "
              (click)="navigateTo(menu.page)"
              [innerHtml]="
                parentMenuTextKey + '.' + menu.menu + '.name' | translate
              "
            ></a>
            <ul *ngIf="menu.subMenus?.length">
              <ng-container
                *ngTemplateOutlet="
                  recursiveSubMenus;
                  context: {
                    subMenuData: menu.subMenus,
                    parentMenuTextKey: parentMenuTextKey + '.' + menu.menu
                  }
                "
              ></ng-container>
            </ul>
          </li>
        </ng-template>

        <ng-container *ngFor="let menu of menuData">
          <li [ngClass]="{ submenu: menu.subMenus?.length }">
            <a (click)="navigateTo(menu.page)" translate
              >{{ "menu." + menu.menu + ".name" }}
              <img
                *ngIf="menu.subMenus?.length"
                src="../../../assets/images/a5.png"
            /></a>

            <ul *ngIf="menu.subMenus?.length">
              <ng-container
                *ngTemplateOutlet="
                  recursiveSubMenus;
                  context: {
                    subMenuData: menu.subMenus,
                    parentMenuTextKey: 'menu.' + menu.menu
                  }
                "
              ></ng-container>
            </ul>
          </li>
          <li>
            <img class="separator" src="../../../assets/images/div2.gif" />
          </li>
        </ng-container>
      </ul>
    </nav>
  </div>
</div>
