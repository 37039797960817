import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CommitteeData } from '../models/committee.model';

export class InitCommitteeContext {
  public static readonly type = '[Committee] init';
}

export class UpdateCommitteeContext {
  public static readonly type = '[Committee] update';
  constructor(readonly update: Partial<CommitteeData>) {}
}

const defaultYear = 'current';

@State<CommitteeData>({
  name: 'committeeState',
  defaults: {year: defaultYear},
})
@Injectable()
export class CommitteeState {
  @Selector()
  public static getState(state: CommitteeData) {
    return state;
  }

  @Action(InitCommitteeContext)
  public ChangeState(
    ctx: StateContext<CommitteeData>,
    action: InitCommitteeContext
  ) {
    ctx.setState({year: defaultYear});
  }

  @Action(UpdateCommitteeContext)
  public Update(
    ctx: StateContext<CommitteeData>,
    action: UpdateCommitteeContext
  ) {
    ctx.patchState(action.update);
  }
}
