import { PublicationInfo } from 'src/app/models/publication.model';

export function getPublicationData(): PublicationInfo[] {
  return [
    {
      year: "2016",
      booklet: "compassion",
      file: "assets/publication/VABAA_booklet_2016_final_002.pdf"
    },
    {
      year: "2014",
      booklet: "hope",
      file: "assets/publication/VABAA_booklet_311014_final_2309_002.pdf"
    },
    {
      year: "2013",
      booklet: "launchNight",
      file: "assets/publication/VABAA_booklet_final_050413_002.pdf"
    }
  ]
}
