import { NgxsConfig } from '@ngxs/store/src/symbols';
import { NgxsDevtoolsOptions } from '@ngxs/devtools-plugin/src/symbols';
import { ActivityState } from './activity.state';
import { SponsorState } from './sponsor.state';
import { OfficeBearerState } from './officeBearers.state';
import { CommitteeState } from './committee.state';
import { ProjectState } from './project.state';

export const STATES_MODULES = [
  SponsorState,
  ActivityState,
  OfficeBearerState,
  CommitteeState,
  ProjectState
];

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
  /**
   * Run in development mode. This will add additional debugging features:
   * - Object.freeze on the state and actions to guarantee immutability
   * todo: you need set production mode
   * import { environment } from '@env';
import { AutoBagDropConfigState } from '../state/auto-bag-drop-config.state';
import { TransactionState } from './transaction/transaction.state';
import { ScanPassportState } from '../workflows/passenger/scan-passport/scan-passport.state';
import { AppWorkflowState } from '../workflows/app/app.workflow.state';
   * developmentMode: !environment.production
   */
  developmentMode: true,
};

export const DEVTOOLS_REDUX_CONFIG: NgxsDevtoolsOptions = {
  /**
   * Whether the dev tools is enabled or note. Useful for setting during production.
   * todo: you need set production mode
   * import { environment } from '@env';
   * disabled: environment.production
   */
  disabled: false,
};
