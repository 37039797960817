import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { OfficeBearerInfo } from 'src/app/models/officeBearer.model';
import { OfficeBearerState } from 'src/app/states/officeBearers.state';
import { getOfficeBearerInfo } from './office-bearers.data';

@Component({
  selector: 'app-office-bearers',
  templateUrl: './office-bearers.component.html',
  styleUrls: ['./office-bearers.component.scss'],
})
export class OfficeBearersComponent implements OnInit, OnDestroy {
  officeBearerSubscription: Subscription;
  officeBearers: OfficeBearerInfo[] = [];
  year = "";

  constructor(private store: Store) {
    this.officeBearerSubscription = this.store
      .select(OfficeBearerState.getState)
      .subscribe((officeBearerData) => {
        if (officeBearerData) {
          this.officeBearers = officeBearerData.officeBearers;
          this.year = officeBearerData.year;
        } else
          throw new Error(`OfficeBearersComponent: invalid office bearer subscription`);
      });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(){
    this.officeBearerSubscription?.unsubscribe();
  }
}
