<h1 [innerHTML]="'merchandise.title' | translate"></h1>
<div class="container">
    <img src="assets/images/merchandise/VABAA-Polo-shirt_website.png">
    <div class="break"></div>
    <div [innerHTML]="'merchandise.shirt' | translate"></div>
</div>
<div class="container">
    <img src="assets/images/merchandise/mug_website.png">
    <div class="break"></div>
    <div [innerHTML]="'merchandise.mug' | translate"></div>
</div>
<div class="split-line"></div>
