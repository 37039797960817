import { ActivityData } from 'src/app/models/activity.model';

export function getActivityData(): ActivityData[] {
  return [
    {
    year: 2021,
      activities: [
        {
          id: 1,
          translationKey: 'lunchJune',
          images: [
            '200698189_938685550261650_181984716060404275_n.jpg',
            '203574245_4260725057319892_1947315231839934718_n.jpg',
            '204127997_487102539047776_3599155633663070780_n.jpg',
            '204484741_510780857043995_43619131190240714_n.jpg',
            '204527613_390131142404796_5577256816673590479_n.jpg',
            '204728589_862248244644339_86449952904188908_n.jpg',
            '204914422_934207520479722_3812035826787290434_n.jpg',
            '205438551_774337163283346_3911356577230708711_n.jpg',
            '200212656_964164674349799_3879980106595402924_n.jpg',
            '204198537_144872387611870_2042201687565007137_n.jpg',
            '204403451_550114906354277_2536416034217097893_n.jpg',
            '204801095_549369129579993_4961362921709864329_n.jpg',

          ],
        },
        
      ]
    },
    {
      year: 2017,
      activities: [
        {
          id: 1,
          translationKey: 'nbnSep',
          images: [
            '21761940_1597214500335761_5041490747324996938_n.jpg',
            '21764761_1597214503669094_2482890359204567872_n.jpg',
            '21766670_1597214543669090_2976600280209945106_n.jpg',
            '22007455_1597214540335757_8402610471263043876_n.jpg',
            '22007611_1597214487002429_7559478207876212589_n.jpg',
          ],
        },
        {
          id: 2,
          translationKey: 'nbnMar',
          images: [
            'NBN-1.jpg',
            'NBN-2.jpg',
            'NBN-3.jpg',
            'NBN-4.jpg',
            'NBN-5.jpg',
          ],
        },
        {
          id: 3,
          translationKey: 'childrenFestival',
          images: [
            '21616216_1591539604236584_4822819119394269427_n.jpg',
            '21558557_1591540720903139_1617120453691323576_n.jpg',
            '21463334_1591539694236575_2750267694420699615_n.jpg',
            '21557887_1591540730903138_946937827283160042_n.jpg',
            '21751340_1591540750903136_2389258856996996136_n.jpg',
            '21743139_1591539917569886_6598260756903371446_n.jpg',
            '21558640_1591539944236550_6988576447776918171_n.jpg',
            '21558628_1591540754236469_1441806871400313029_n.jpg',
            '21557564_1591540794236465_5616426570099141904_n.jpg',
            '21557640_1591540127569865_4706502087019038154_n.jpg',
          ],
        },
        {
          id: 4,
          translationKey: 'missionTrip',
          images: [
            'MT-1.jpg',
            'MT-2.jpg',
            'MT-3.jpg',
            'MT-4.jpg',
            'MT-5.jpg',
            'MT-6.jpg',
            'MT-7.jpg',
            'MT-8.jpg',
            'MT-9.jpg',
            'MT-10.jpg',
            'MT-11.jpg',
            'MT-12.jpg',
            'MT-13.jpg',
            'MT-14.jpg',
            'MT-15.jpg',
          ],
        },
      ],
    },
    {
      year: 2016,
      activities: [
        {
          id: 1,
          translationKey: 'afd',
          images: [
            'AFD-1.jpg',
            'AFD-2.jpg',
            'AFD-3.jpg',

            'AFD-4.jpg',
            'AFD-5.jpg',
            'AFD-6.jpg',
            'AFD-7.jpg',
            'AFD-8.jpg',
            'AFD-9.jpg',

            'AFD-10.jpg',
            'compassion-2016_poster1-page-001_a7.jpg',
            'Compassion-2016_viet_poster1-page-001.jpg',
          ],
        },
      ],
    },
    {
      year: 2015,
      activities: [
        {
          id: 1,
          translationKey: 'nbnFeb',
          images: [
            '14723_806165719463682_7090105594324165715_n.jpg',
            '15880_806164429463811_42233955146925704_n.jpg',
            '18226_806164739463780_4419275135355761549_n.jpg',
            '1422377_806165652797022_4233090628796699690_n.jpg',
            '10297607_10153163817552146_7087280433303307673_n.jpg',
            '10392550_806166479463606_2829034246682081707_n.jpg',
            '10423956_806169016130019_8127084700606572058_n.jpg',
            '10897931_806169379463316_1757963354241380639_n.jpg',
            '10953218_806170029463251_866591462885719146_n.jpg',
            '10953366_806169612796626_8601297619082771964_n.jpg',
            '10959776_806169519463302_1602583536737264506_n.jpg',
            '10991291_806164986130422_7857338602609767044_n.jpg',
            '10994179_806169459463308_546994548869345273_n.jpg',
            '10996494_806164512797136_1351486091691266479_n.jpg',
            '10998044_806166712796916_547100880227517311_n.jpg',
            '11010319_806169159463338_4551981830072741430_n.jpg',
            '11014663_806171232796464_1127432488685990350_n.jpg',
            '11021127_806169882796599_6040245900545636416_n.jpg',
            'cindy.jpg',
            'girls.jpg',
            'girls_1.jpg',
            'Ivy.jpg',
            'Nat.jpg',
            'Natandcin.jpg',
            'Thu.jpg',
          ],
        },
        {
          id: 2,
          translationKey: 'nbnAug',
          images: [
            '5D3_8285.jpg',
            '5D3_8286.jpg',
            '5D3_8289.jpg',
            '5D3_8291.jpg',
            '5D3_8299.jpg',
            '5D3_8303.jpg',
            '5D3_8304.jpg',
            '5D3_8306.jpg',
            '5D3_8309.jpg',
            '5D3_8315.jpg',
            '5D3_8320.jpg',
            '5D3_8323.jpg',
            '5D3_8330.jpg',
            '5D3_8335.jpg',
            '5D3_8338.jpg',
            '5D3_8340.jpg',
            '5D3_8342.jpg',
            '5D3_8349.jpg',
            '5D3_8351.jpg',
            '5D3_8352.jpg',
            '5D3_8355.jpg',
          ],
        },
        {
          id: 3,
          translationKey: 'floodQuangNinh',
          images: [
            'IMG_6083.JPG',
            'IMG_6084.JPG',
            'IMG_6085.JPG',
            'IMG_6086.JPG',
            'IMG_6087.JPG',
            'IMG_6089.JPG',
            'IMG_6095.JPG',
            'IMG_6098.JPG',
            'IMG_6099.JPG',
            'IMG_6102.JPG',
            'IMG_6104.JPG',
            'file-page1.jpg',
            'IMG_6118.jpg',
            'IMG_6119.jpg',
          ],
        },
        {
          id: 4,
          translationKey: 'afd',
          images: [
            '0.jpg',
            '1.jpg',
            '2.jpg',
            '3.jpg',
            '4.jpg',
            '5.jpg',
            '6.jpg',
            '7.jpg',
            '8.jpg',
            '9.jpg',
            '10.jpg',
            '11.jpg',
            '12.jpg',
            '13.jpg',
            '14.jpg',
            '15.jpg',
            '16.jpg',
            '17.jpg',
            '18.jpg',
            '19.jpg',
            '20.jpg',
            '21.jpg',
            '22.jpg',
            '23.jpg',
            '24.jpg',
            '25.jpg',
            '26.jpg',
            '27.jpg',
            '28.jpg',
            '29.jpg',
          ],
        },
      ],
    },
    {
      year: 2014,
      activities: [
        {
          id: 1,
          translationKey: 'afd',
          images: [
            'IMG_1904.jpg',
            'IMG_1906.jpg',
            'IMG_1911.jpg',
            'IMG_1916.jpg',
            'IMG_1931.jpg',
            'IMG_1947.jpg',
            'IMG_1950.jpg',
            'IMG_1952.jpg',
            'IMG_1953.jpg',
            'IMG_1954.jpg',
            'IMG_1957.jpg',
            'IMG_1978.jpg',
            'IMG_1995.jpg',
            'IMG_1997.jpg',
            'IMG_2026.jpg',
            'IMG_2037.jpg',
            'IMG_2057.jpg',
            'IMG_2066.jpg',
            'IMG_2079.jpg',
            'IMG_2082.jpg',
            'IMG_2086.jpg',
            'IMG_2093.jpg',
            'IMG_2144.jpg',
            'IMG_2150.jpg',
            'IMG_2170.jpg',
          ],
        },
        {
          id: 2,
          translationKey: 'nursingHome',
          images: [
            'IMG_5357.JPG',
            'IMG_5362.JPG',
            'IMG_5364.JPG',
            'IMG_5366.JPG',
            'IMG_5367.JPG',
            'IMG_5368.JPG',
            'IMG_5371.JPG',
            'IMG_5378.JPG',
            'IMG_5379.JPG',
            'IMG_5380.JPG',
            'IMG_5383.JPG',
          ],
        },
        {
          id: 3,
          translationKey: 'nbnFeb',
          images: [
            'IMG_5154.JPG',
            'IMG_5158.JPG',
            'IMG_5164.JPG',
            'IMG_5206.JPG',
            'IMG_5215.JPG',
            'img_8685.jpg',
            'img_8687.jpg',
            'img_8681.jpg',
            'img_8674.jpg',
            'img_8652.jpg',
            'img_8645.jpg',
            'img_8636.jpg',
            'img_8625.jpg',
            'img_8623.jpg',
            'img_8669.jpg',
          ],
        },
      ],
    },
    {
      year: 2013,
      activities: [
        {
          id: 1,
          translationKey: 'typhoonHaiyan',
          images: [
            'photo.JPG',
            '1537929_779846882032915_959902031_o.jpg',
            '1517761_779846075366329_617159617_o.jpg',
            '1487829_779845932033010_115264512_o.jpg',
            '1493587_779847692032834_1454411207_o.jpg',
            'photo-1.JPG',
            '1519162_779847405366196_150755500_o.jpg',
            '1525601_10151896442793997_747615565_n.jpg',
            '1518974_683648725008272_1515875176_o.jpg',
          ],
        },
        {
          id: 2,
          translationKey: 'moonFestival',
          images: [
            'IMG_4242.jpg',
            'IMG_4230.jpg',
            'IMG_4234.jpg',
            'IMG_4236.jpg',
            'IMG_4239.jpg',
            'IMG_4245.jpg',
            'IMG_4247.jpg',
            'IMG_4251.jpg',
            'IMG_4229.jpg',
            'IMG_4252.jpg',
            'IMG_4254.jpg',
            'IMG_4260.jpg',
            'IMG_4261.jpg',
            'IMG_4268.jpg',
            'IMG_4271.jpg',
            'IMG_4272.jpg',
            'IMG_4275.jpg',
            'IMG_4281.jpg',
            'IMG_4283.jpg',
            'IMG_4286.jpg',
            'IMG_4288.jpg',
            'IMG_4290.jpg',
            'IMG_4292.jpg',
            'IMG_4565.jpg',
            'IMG_4567.jpg',
            'IMG_4568.jpg',
            'IMG_4570.jpg',
            'IMG_4573.jpg',
            'IMG_4576.jpg',
            'IMG_4578.jpg',
          ],
        },
        {
          id: 3,
          translationKey: 'nursingHome',
          images: [
            'IMG_6017.jpg',
            'IMG_6018.jpg',
            'IMG_6024.jpg',
            'IMG_6027.jpg',
            'IMG_6030.jpg',
            'IMG_6038.jpg',
            'IMG_6040.jpg',
            'IMG_6043.jpg',
            'IMG_6047.jpg',
            'IMG_6049.jpg',
            'IMG_6056.jpg',
            'IMG_6059.jpg',
            'IMG_6062.jpg',
            'IMG_6064.jpg',
            'IMG_6065.jpg',
            'IMG_6066.jpg',
            'IMG_6070.jpg',
            'IMG_6072.jpg',
          ],
        },
        {
          id: 4,
          translationKey: 'launchNight',
          images: [
            'IMG_0243.jpg',
            'IMG_0258.jpg',
            'IMG_0259.jpg',
            'IMG_0289.jpg',
            'IMG_0328.jpg',
            'IMG_0312.jpg',
            'IMG_0376.jpg',
            'IMG_0357.jpg',
            'IMG_0354.jpg',
            'IMG_0356.jpg',
            'IMG_0389.jpg',
            'IMG_0421.jpg',
            'IMG_0411.jpg',
            'IMG_0407.jpg',
            'IMG_0408.jpg',
            'IMG_0409.jpg',
            'IMG_0400.jpg',
            'IMG_0462.jpg',
            'IMG_0435.jpg',
            'IMG_0428.jpg',
            'IMG_0432.jpg',
          ],
        },
        {
          id: 5,
          translationKey: 'tet',
          images: [
            'VABAA-Activities-Fundraising-Xuan-2013-1.jpg',
            'VABAA-Activities-Fundraising-Xuan13-2.jpg',
          ],
        },
      ],
    },
    {
      year: 2012,
      activities: [
        {
          id: 1,
          translationKey: 'xMas',
          images: ['Vabaa-Activities-Member-get-together-Xmas-2012.jpg'],
        },
        {
          id: 2,
          translationKey: 'bringelly',
          images: ['VABAA-Activities-Fundraising-Bringely.jpg'],
        },
        {
          id: 3,
          translationKey: 'missionTrip',
          images: ['Pre-VABAA-Mission-trip-Feb-2012-a.jpg'],
        },
      ],
    },
  ];
}
