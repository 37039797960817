import { MenuModel } from 'src/app/models/menu.model';
import { ProjectType } from 'src/app/models/project.model';
import { getActivityData } from '../activity/activity.data';
import { getProjectData } from '../project/project.data';
import { getSponsorData } from '../sponsor/sponsor-data';
import { getCommitteeInfo } from '../who-we-are/committee/committee.data';
import { getOfficeBearerInfo } from '../who-we-are/office-bearers/office-bearers.data';
import { getPublicationData } from '../who-we-are/publication/publication.data';

export function getMenuData(): MenuModel[] {
  return [
    {
      menu: 'home',
      page: 'home',
    },
    {
      menu: 'whoWeAre',
      subMenus: [
        { menu: 'mission', page: 'mission' },
        { menu: 'values', page: 'values' },
        {
          menu: 'officeBearers',
          subMenus: getOfficeBearers(),
        },
        {
          menu: 'committee',
          subMenus: getCommittee(),
        },
        { menu: 'report', page: 'governance-report' },
        {
          menu: 'publication',
          subMenus: getPublication(),
        },
        { menu: 'merchandise', page: 'merchandise' },
      ],
    },
    {
      menu: 'takeAction',
      subMenus: [
        { menu: 'donate', page: 'donation' },
        { menu: 'volunteer', page: 'volunteer' },
      ],
    },
    {
      menu: 'sponsors',
      subMenus: [
        {
          menu: 'current',
          subMenus: getSponsors(2019),
        },
        {
          menu: '2018',
          subMenus: getSponsors(2018),
        },
        {
          menu: '2017',
          subMenus: getSponsors(2017),
        },
        {
          menu: '2016',
          subMenus: getSponsors(2016),
        },
        {
          menu: '2015',
          subMenus: getSponsors(2015),
        },
        {
          menu: '2014',
          subMenus: getSponsors(2014),
        },
      ],
    },
    {
      menu: 'projects',
      subMenus: [
        {
          menu: 'orphan',
          subMenus: getProjects('Orphan'),
        },
        {
          menu: 'edu',
          subMenus: getProjects('Education'),
        },
        {
          menu: 'nature',
          subMenus: getProjects('NaturalDisaster'),
        },
        {
          menu: 'human',
          subMenus: getProjects('Humantarian'),
        },
        {
          menu: 'healthMedical',
          subMenus: getProjects('Health'),
        },
      ],
    },
    {
      menu: 'activities',
      subMenus: [
        {
          menu: 'current',
          subMenus: getActivities(2021),
        },
        {
          menu: '2017',
          subMenus: getActivities(2017),
        },
        {
          menu: '2016',
          subMenus: getActivities(2016),
        },
        {
          menu: '2015',
          subMenus: getActivities(2015),
        },
        {
          menu: '2014',
          subMenus: getActivities(2014),
        },
        {
          menu: '2013',
          subMenus: getActivities(2013),
        },
        {
          menu: '2012',
          subMenus: getActivities(2012),
        },
      ],
    },
    {
      menu: 'contact',
      page: 'contact',
    },
  ];
}

function getSponsors(year: number): MenuModel[] {
  return getSponsorData()
    .filter((x) => x.year === year)[0]
    .sponsors.map((sponsor) => {
      return {
        menu: sponsor.businessName,
        sponsorData: { year: year, id: sponsor.id },
      } as MenuModel;
    });
}

function getOfficeBearers(): MenuModel[] {
  return getOfficeBearerInfo().map((x) => {
    return {
      menu: 'officeBearer.year.' + x.year,
      officeBearers: { year: x.year, id: 0 },
      subMenus: x.officeBearers.map((officeBearer) => {
        return {
          menu: '',
          officeBearers: {
            year: x.year,
            id: officeBearer.id,
            role: officeBearer.role,
            name: officeBearer.name,
          },
        };
      }),
    };
  });
}

function getCommittee(): MenuModel[] {
  return getCommitteeInfo().map((x) => {
    return {
      menu: 'committee.' + x.year + '.name',
      committee: { year: x.year },
    };
  });
}

function getPublication(): MenuModel[] {
  return getPublicationData().map((x) => {
    return {
      menu: `year.${x.year}`,
      subMenus: [{ menu: x.booklet, file: x.file }],
    };
  });
}

function getProjects(projectType: ProjectType): MenuModel[] {
  return getProjectData()
    .filter((x) => x.projectType === projectType)[0]
    .projects.map((y) => {
      return {
        menu: `project.projects.${y.translationKey}.title`,
        project: {
          type: projectType,
          id: y.id,
        },
      };
    });
}

function getActivities(year: number): MenuModel[] {
  return getActivityData()
    .filter((x) => x.year === year)[0]
    .activities.map((y) => {
      return {
        menu: `activity.${year}.${y.translationKey}.title`,
        activityData: {
          year: year,
          id: y.id,
        },
      };
    });
}
