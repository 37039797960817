<h2
  *ngIf="activityContext.activityInfo.translationKey"
  [innerHTML]="
    'activity.' +
      activityContext.year +
      '.' +
      activityContext.activityInfo.translationKey +
      '.title' | translate
  "
></h2>
<p
  *ngIf="activityContext.activityInfo.translationKey"
  [innerHTML]="
    'activity.' +
      activityContext.year +
      '.' +
      activityContext.activityInfo.translationKey +
      '.summary' | translate
  "
></p>
<div>
  <p class="imgContainer" gallerize>
    <img
      *ngFor="let img of activityContext.activityInfo.images"
      [src]="
        'assets/images/activity/' +
        activityContext.year +
        '/' +
        activityContext.activityInfo.translationKey +
        '/' +
        img
      "
    />
  </p>
</div>
