import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', [animate('0.5s ease-in-out')]),
    ]),
  ],
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit {
  constructor() {}

  selectedIndex = 0;
  imgList: Image[] = [
    {
      url: 'assets/images/slider/nbn.jpg',
      title: 'Nho Ban Ngheo',
      desc: 'Raise fund for poor people.',
    },
    {
      url: 'assets/images/slider/orphanage-in-burma-myanmar-.jpg',
      title: 'Orphanage in Burma (Myanmar)',
      desc: 'Raising additional funds to assist Fr Philip Shwe in providing support to an orphanage in Burma (Myanmar). ',
    },
    {
      url: 'assets/images/slider/tasmanian-bushfire.jpg',
      title: 'Tasmanian Bushfires Relief 2013',
      desc: 'Every dollar donated will go to help those affected.',
    },
    {
      url: 'assets/images/slider/vabaa-activities-nursing-home-group.jpg',
      title: 'VABAA - Activities',
      desc: 'Visiting the Nursing Home Group.',
    },
    {
      url: 'assets/images/slider/groupphoto2-1-.jpg',
      title: 'Kien Giang Scholarship Program',
      desc: 'Education support to poor children in South Vietnam (Kien Giang).',
    },
  ];

  ngOnInit(): void {
    this.selectNextImg();
  }

  selectNextImg = () => {
    setTimeout(() => {
      this.selectedIndex++;
      if (this.selectedIndex === this.imgList.length) this.selectedIndex = 0;
      this.selectNextImg();
    }, 5000);
  };
}
interface Image {
  url: string;
  title: string;
  desc: string;
}
