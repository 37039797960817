<div class="container">
  <ul>
    <li *ngFor="let img of imgList; let i = index">
      <img @fade *ngIf="i == selectedIndex" [src]="img.url" />
      <div @fade *ngIf="i == selectedIndex">
        {{ img.title }}<br />{{ img.desc }}
      </div>
    </li>
  </ul>
</div>
