import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { ProjectInfo } from 'src/app/models/project.model';
import { ProjectState } from 'src/app/states/project.state';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {
  projectSubscription: Subscription;

  projectInfo = new ProjectInfo();

  constructor(private store: Store) {
    this.projectSubscription = this.store
      .select(ProjectState.getState)
      .subscribe((projectInfo) => {
        if (projectInfo) {
          this.projectInfo = projectInfo;
        } else
          throw new Error(`ProjectComponent: invalid project subscription`);
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.projectSubscription?.unsubscribe();
  }
}
