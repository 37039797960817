import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getMenuData } from './components/menu/menu.data';
import { MenuModel } from './models/menu.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', '../assets/menu/css/styles.scss'],
})
export class AppComponent {
  title = 'vabaa';
  menuData: MenuModel[];

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
    this.menuData = getMenuData();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

}
