import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { getOfficeBearerInfo } from '../components/who-we-are/office-bearers/office-bearers.data';
import { OfficeBearerData } from '../models/officeBearer.model';

export class InitOfficeBearerContext {
  public static readonly type = '[OfficeBearer] init';
}

export class UpdateOfficeBearerContext {
  public static readonly type = '[OfficeBearer] update';
  constructor(readonly update: Partial<OfficeBearerData>) {}
}

const defaultYear = 'current';

@State<OfficeBearerData>({
  name: 'officeBearerState',
  defaults: { year: defaultYear, officeBearers: getDefaultOfficeBearers() },
})
@Injectable()
export class OfficeBearerState {
  @Selector()
  public static getState(state: OfficeBearerData) {
    return state;
  }

  @Action(InitOfficeBearerContext)
  public ChangeState(
    ctx: StateContext<OfficeBearerData>,
    action: InitOfficeBearerContext
  ) {
    ctx.setState({
      year: defaultYear,
      officeBearers: getDefaultOfficeBearers(),
    });
  }

  @Action(UpdateOfficeBearerContext)
  public Update(
    ctx: StateContext<OfficeBearerData>,
    action: UpdateOfficeBearerContext
  ) {
    ctx.patchState(action.update);
  }
}

function getDefaultOfficeBearers() {
  return getOfficeBearerInfo().filter((x) => x.year === defaultYear)[0]
    ?.officeBearers;
}
