<div class="container">
  <div class="content">
    <div class="menu-container" *ngFor="let menu of menuData">
      <div class="content">
        <h3 translate>{{ "menu." + menu.menu + ".name" }}</h3>
        <ul class="footer">
          <ng-container *ngFor="let subMenu of menu.subMenus">
            <li *ngIf="subMenu.page">
              <a (click)="navigateTo(subMenu.page)" translate>{{
                "menu." + menu.menu + "." + subMenu.menu + ".name"
              }}</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div class="menu-container">
      <div class="content">
        <h3 translate>Where We Work</h3>
        <img
          src="assets/images/wherewework.png"
          style="width: 20vw"
        />
      </div>
    </div>
  </div>
</div>

<div
  style="
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  "
>
  <div style="width: 80vw; display: flex; justify-content: left">
    <a href="https://www.facebook.com/vabaainc" target="_blank"
      ><img src="assets/images/facebook.png" width="32" height="32" />
    </a>
    <span style="font-size: 12px; margin-left: 100px"
      >© Viet-Aust Bac Ai Association Inc. 2012</span
    >
  </div>
</div>
