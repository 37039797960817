<div class="container">
  <ng-image-slider
    [images]="imageObject"
    [infinite]="true"
    [autoSlide]="20"
    [imageSize]="{ width: '100%', height: '530' }"
    [showArrow]="false"
    [animationSpeed]="2"
    [slideImage]="1"
  ></ng-image-slider>
</div>
