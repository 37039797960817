<h1 [innerHTML]="'committee.' + year + '.name' | translate"></h1>
<div class="image-container">
  <img
    [src]="'assets/images/committee/' + year + '/group.jpg'"
    class="portrait"
  />
</div>

<span [innerHTML]="'committee.' + year + '.description' | translate"></span>
<div class="split-line"></div>
