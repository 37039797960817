import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { CommitteeState } from 'src/app/states/committee.state';

@Component({
  selector: 'app-committee',
  templateUrl: './committee.component.html',
  styleUrls: ['./committee.component.scss']
})
export class CommitteeComponent implements OnInit, OnDestroy {
  committeeSubscription: Subscription;
  year = "";

  constructor(private store: Store) {
    this.committeeSubscription = this.store
      .select(CommitteeState.getState)
      .subscribe((committeeData) => {
        if (committeeData) {
          this.year = committeeData.year;
        } else
          throw new Error(`CommitteeComponent: invalid committee subscription`);
      });
   }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.committeeSubscription?.unsubscribe();
  }
}
