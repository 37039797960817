import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { BackgroundImageSliderComponent } from './components/background-image-slider/background-image-slider.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { NgxsStoreModule } from './states/store.module';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { GallerizeModule } from '@ngx-gallery/gallerize';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SponsorComponent } from './components/sponsor/sponsor.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { MoneyPipe } from './components/pipes/number.pipe';
import { MissionComponent } from './components/who-we-are/mission/mission.component';
import { ValuesComponent } from './components/who-we-are/values/values.component';
import { OfficeBearersComponent } from './components/who-we-are/office-bearers/office-bearers.component';
import { CommitteeComponent } from './components/who-we-are/committee/committee.component';
import { GovernanceReportComponent } from './components/who-we-are/governance-report/governance-report.component';
import { PublicationComponent } from './components/who-we-are/publication/publication.component';
import { MerchandiseComponent } from './components/who-we-are/merchandise/merchandise.component';
import { DonationComponent } from './components/take-action/donation/donation.component';
import { VolunteerComponent } from './components/take-action/volunteer/volunteer.component';
import { ProjectComponent } from './components/project/project.component';
import { ContactComponent } from './components/contact/contact.component';
import { ActivityComponent } from './components/activity/activity.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    HeaderComponent,
    BackgroundImageSliderComponent,
    FooterComponent,
    SponsorComponent,
    ImageSliderComponent,
    MoneyPipe,
    MissionComponent,
    ValuesComponent,
    OfficeBearersComponent,
    CommitteeComponent,
    GovernanceReportComponent,
    PublicationComponent,
    MerchandiseComponent,
    DonationComponent,
    VolunteerComponent,
    ProjectComponent,
    ContactComponent,
    ActivityComponent
  ],
  imports: [
    NgxsRouterPluginModule.forRoot(),

    NgxsStoreModule,
    BrowserModule,
    AppRoutingModule,
    NgImageSliderModule,
    BrowserAnimationsModule,
    // GalleryModule.forRoot(),
    // LightboxModule.forRoot(),
    GallerizeModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot([
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'sponsor',
        component: SponsorComponent,
      },
      {
        path: 'office-bearers',
        component: OfficeBearersComponent,
      },
      {
        path: 'committee',
        component: CommitteeComponent,
      },
      {
        path: 'mission',
        component: MissionComponent
      },
      {
        path: 'values',
        component: ValuesComponent
      },
      {
        path: 'governance-report',
        component: GovernanceReportComponent
      },
      {
        path: 'merchandise',
        component: MerchandiseComponent
      },
      {
        path: 'donation',
        component: DonationComponent
      },
      {
        path: 'volunteer',
        component: VolunteerComponent
      },
      {
        path: 'project',
        component: ProjectComponent
      },
      {
        path: 'activity',
        component: ActivityComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      },
      { path: '**', redirectTo: '/home' },
    ]),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
