import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ProjectInfo } from '../models/project.model';

export class InitProjectContext {
  public static readonly type = '[Project] init';
}

export class UpdateProjectContext {
  public static readonly type = '[Project] update';
  constructor(readonly update: Partial<ProjectInfo>) {}
}

@State<ProjectInfo>({
  name: 'projectState',
  defaults: new ProjectInfo(),
})
@Injectable()
export class ProjectState {
  @Selector()
  public static getState(state: ProjectInfo) {
    return state;
  }

  @Action(InitProjectContext)
  public ChangeState(
    ctx: StateContext<ProjectInfo>,
    action: InitProjectContext
  ) {
    ctx.setState(new ProjectInfo());
  }

  @Action(UpdateProjectContext)
  public Update(
    ctx: StateContext<ProjectInfo>,
    action: UpdateProjectContext
  ) {
    ctx.patchState(action.update);
  }
}
