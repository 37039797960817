

<div style="clear: both; margin-top: 20px; justify-content: center">
  <app-image-slider></app-image-slider>
</div>

<div style="clear: both; margin-top: 20px; justify-content: center">
  <h1 [innerHTML]="'home.welcome' | translate"></h1>
  <span [innerHTML]="'home.welcomeText' | translate"></span>
</div>

<div class="split-line"></div>
<h2 [innerHTML]="'home.quote' | translate"></h2>
<blockquote>
  <span id="quote"
    >{{quotes[selectedQuote].quote}}</span
  >
  <cite> <span id="author">{{quotes[selectedQuote].author}}</span></cite>
</blockquote>
