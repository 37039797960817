import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ActivityInfo } from '../models/activity.model';

export class ActivityContextModel {
  year = 0
  activityInfo: ActivityInfo = new ActivityInfo();
}

export class InitActivityContext {
  public static readonly type = '[Activity] init';
}

export class UpdateActivityContext {
  public static readonly type = '[Activity] update';
  constructor(readonly update: Partial<ActivityContextModel>) {}
}

@State<ActivityContextModel>({
  name: 'activityState',
  defaults: new ActivityContextModel(),
})
@Injectable()
export class ActivityState {
  @Selector()
  public static getState(state: ActivityContextModel) {
    return state;
  }

  @Action(InitActivityContext)
  public Init(
    ctx: StateContext<ActivityContextModel>,
    action: InitActivityContext
  ) {
    ctx.setState(new ActivityContextModel());
  }

  @Action(UpdateActivityContext)
  public Update(
    ctx: StateContext<ActivityContextModel>,
    action: UpdateActivityContext
  ) {
    ctx.patchState(action.update);
  }
}
