<h1 [innerHTML]="'values.title' | translate"></h1>
<span [innerHTML]="'values.description' | translate"></span>
<div class="split-line"></div>
<table
  style="width: 834px; height: 369px; float: left"
  cellspacing="4"
  cellpadding="0"
  border="0"
>
  <tbody>
    <tr>
      <td valign="top">
        <h2 translate>values.compassion.title</h2>
        <span [innerHTML]="'values.compassion.description' | translate"></span>
      </td>
      <td>&nbsp;</td>
      <td valign="top">
        <h2 translate>values.dignity.title</h2>
        <span [innerHTML]="'values.dignity.description' | translate"></span><p>
          Treat all people with care and acknowledge that each person is unique
          in the eyes of God, by recognising that everyone associated with us
          has diverse needs: physical, emotional, psychological and spiritual.
        </p>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <h2 translate>values.excellence.title</h2>
        <span [innerHTML]="'values.excellence.description' | translate"></span>
      </td>
      <td>&nbsp;</td>
      <td valign="top">
        <h2 translate>values.unity.title</h2>
        <span [innerHTML]="'values.unity.description' | translate"></span>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <h2 translate>values.mercy.title</h2>
        <span [innerHTML]="'values.mercy.description' | translate"></span>
      </td>
      <td>&nbsp;</td>
      <td valign="top">
        <h2 translate>values.hospitality.title</h2>
        <span [innerHTML]="'values.hospitality.description' | translate"></span>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <h2 translate>values.respect.title</h2>
        <span [innerHTML]="'values.respect.description' | translate"></span>
      </td>
      <td>&nbsp;</td>
      <td valign="top">
        <h2 translate>values.humility.title</h2>
        <span [innerHTML]="'values.humility.description' | translate"></span>
        <p>&nbsp;</p>
      </td>
    </tr>
  </tbody>
</table>

