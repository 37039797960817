<div class="header">
  <div class="container">
    <div class="logo">
      <a href="/">
        <img src="assets/images/logo.png" width="232" height="89"
      /></a>
    </div>
    <div class="abn">ABN: 50 513 661 247</div>
    <div class="lang-bar">
      <a [ngClass]="activeLang === 'en' ? 'active-lang' : 'lang'" [routerLink]="" (click)="useLanguage('en')">English</a
      > |
      <a [ngClass]="activeLang === 'vn' ? 'active-lang' : 'lang'" [routerLink]="" (click)="useLanguage('vn')"
        >Vietnamese</a
      >
    </div>
  </div>
</div>
