import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SponsorInfo } from '../models/sponsor.model';

export class InitSponsorContext {
  public static readonly type = '[Sponsor] init';
}

export class UpdateSponsorContext {
  public static readonly type = '[Sponsor] update';
  constructor(readonly update: Partial<SponsorInfo>) {}
}

@State<SponsorInfo>({
  name: 'sponsorState',
  defaults: new SponsorInfo(),
})
@Injectable()
export class SponsorState {
  @Selector()
  public static getState(state: SponsorInfo) {
    return state;
  }

  @Action(InitSponsorContext)
  public ChangeState(
    ctx: StateContext<SponsorInfo>,
    action: InitSponsorContext
  ) {
    ctx.setState(new SponsorInfo());
  }

  @Action(UpdateSponsorContext)
  public Update(
    ctx: StateContext<SponsorInfo>,
    action: UpdateSponsorContext
  ) {
    ctx.patchState(action.update);
  }
}
