import { Component, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MenuModel } from 'src/app/models/menu.model';
import { getMenuData } from '../menu/menu.data';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  menuData = getMenuData().filter(
    (x) =>
      ((x.subMenus?.length ?? 0) > 0 && x.subMenus?.some((x) => x.page))
  );
  constructor(private store: Store) {}

  ngOnInit(): void {}

  navigateTo(page?: string) {
    if (page) this.store.dispatch(new Navigate([page]));
  }
}
