import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-background-image-slider',
  templateUrl: './background-image-slider.component.html',
  styleUrls: ['./background-image-slider.component.scss']
})
export class BackgroundImageSliderComponent implements OnInit {

  imageObject = [
    {
      image: 'assets/images/background-slider/vb_16.jpg',
      thumbImage: 'assets/images/background-slider/vb_16.jpg',
    },
    {
      image: 'assets/images/background-slider/vb_32.jpg',
      thumbImage: 'assets/images/background-slider/vb_32.jpg',
    },
    {
      image: 'assets/images/background-slider/vb_33-1-.jpg',
      thumbImage: 'assets/images/background-slider/vb_33-1-.jpg',
    },
    {
      image: 'assets/images/background-slider/vb_133.jpg',
      thumbImage: 'assets/images/background-slider/vb_133.jpg',
    },
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
