import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { ActivityContextModel, ActivityState } from 'src/app/states/activity.state';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit, OnDestroy {
  activitySubscription: Subscription;
  activityContext = new ActivityContextModel();

  constructor(private store: Store) {
    this.activitySubscription = this.store
      .select(ActivityState.getState)
      .subscribe((activityContext) => {
        if (activityContext) {
          this.activityContext = activityContext;
        } else
          throw new Error(`ActivityComponent: invalid activity subscription`);
      });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.activitySubscription?.unsubscribe();
  }
}

