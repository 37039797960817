import { Component, OnInit } from '@angular/core';
import { getRandomInt } from 'src/app/utils/math';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor() {}

  selectedQuote = 0;
  quotes: Quote[] = [
    {
      quote:
        'Even the rich are hungry for love, for being cared for, for being wanted, for having someone to call their own.',
      author: 'Mother Teresa',
    },
    {
      quote: `If you can't feed a hundred people, then feed just one.`,
      author: 'Mother Teresa',
    },
    {
      quote: `At the end of life we will not be judged by how many diplomas we have received, how much money we have made, how many great things we have done.
        We will be judged by “I was hungry, and you gave me something to eat, I was naked and you clothed me. I was homeless, and you took me in."`,
      author: 'Mother Teresa',
    },
    {
      quote:
        'Love cannot remain by itself – it has no meaning. Love has to be put into action and that action is service.',
      author: 'Mother Teresa',
    },
    {
      quote:
        'We make a living by what we get, but we make a life by what we give.',
      author: 'Winston Churchill',
    },
    {
      quote: 'It is more agreeable to have the power to give than to receive.',
      author: 'Winston Churchill',
    },
    {
      quote:
        'God does watch over us and does notice us, but it is usually through someone else that he meets our needs.',
      author: 'Spencer W. Kimball',
    },
    {
      quote: 'As the purse is emptied, the heart is filled.',
      author: 'Victor Hugo',
    },
    {
      quote: 'Every charitable act is a stepping stone towards heaven.',
      author: 'Henry Ward Beecher',
    },
    {
      quote: 'To pity distress is but human; to relieve it is Godlike.',
      author: 'Horace Mann',
    },
    {
      quote: 'Only a life lived for others is a life worthwhile.',
      author: 'Albert Einstein',
    },
    {
      quote:
        'He who wishes to secure the good of others, has already secured his own.',
      author: 'Confucius',
    },
    {
      quote:
        'The best way to find yourself is to lose yourself in the service of others.',
      author: 'Mahatma Gandhi',
    },
    {
      quote: 'Everyone can be great, because everyone can serve.',
      author: 'Martin Luther King, Jr',
    },
  ];

  ngOnInit(): void {
    this.selectedQuote = getRandomInt(this.quotes.length);
  }
}

interface Quote {
  quote: string;
  author: string;
}
