import { SponsorData, SponsorInfo } from 'src/app/models/sponsor.model';

export function getSponsorData(): SponsorData[] {
  return [
    {
      year: 2019,
      sponsors: [
        {
          ...new SponsorInfo(),
          id: 1,
          businessName: 'Realtime Business Solutions',
          address: `Level 5, 85 George Street<br>
          Parramatta, N.S.W. 2150 – AUSTRALIA`,
          phone: '+61 2 9893 9255 ',
          website: 'http://rbs-tops.com/',
          logo: 'rbs-logo-custom-.png',
          donation: 40000,
        },
        {
          ...new SponsorInfo(),
          id: 2,
          businessName: 'Van Long Machinery P/L',
          address: `Unit 3 - 282 Milperra Road , Revesby NSW 2212`,
          phone: '(02) 9772 3375',
          donation: 3100,
        },
        {
          ...new SponsorInfo(),
          id: 3,
          businessName: 'A Maximum Security Shutters',
          address: `34 Seville Street, Villawood NSW 2163, Australia`,
          phone: '(02) 9755 7488',
          email: 'amss_aust@hotmail.com',
          website: 'www.amsshutter.com.au',
          logo: 'amss.jpg',
          donation: 2000,
        },
        {
          ...new SponsorInfo(),
          id: 4,
          businessName: 'T & L Car Body Repairs Pty Ltd',
          name: 'Mr Tri &Mrs Uyen Nguyen',
          address: `8/76 Hume High Way, Lanvale NSW 2166, Australia`,
          phone: '(02) 9726 6370',
          email: 'tandlrepair@yahoo.com.au',
          logo: 'tlCarBody.jpg',
          donation: 2000,
        },
        {
          ...new SponsorInfo(),
          id: 5,
          businessName: 'Cha Lua Xinh Xinh',
          address: `49 Helen Street, Sefton NSW 2162`,
          phone: '(02) 9743 9990',
          mobile: '0431 940 490',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 6,
          businessName: 'Shop DiamondBeaute Liverpool',
          donation: 500,
        },
        {
          ...new SponsorInfo(),
          id: 7,
          businessName: 'Lawyer Cung Dinh Loc & Dr Diep Nguyen',
          donation: 500,
        },
        {
          ...new SponsorInfo(),
          id: 8,
          businessName: 'Vu Security Doors & Gates PTY. LTD',
          address: `6/34 Chadderton St, Cabramatta NSW 2166`,
          phone: '0412 657 214',
          donation: 300,
        },
        {
          ...new SponsorInfo(),
          id: 9,
          businessName: 'Hai Son Shipping & Transport P/L',
          address: `87 Marigold St, Revesby NSW, 2212`,
          phone: '(02) 9773 5455',
          donation: 250,
        },
      ],
    },
    {
      year: 2018,
      sponsors: [
        {
          ...new SponsorInfo(),
          id: 1,
          businessName: 'Realtime Business Solutions',
          address: `Level 5, 85 George Street<br>
          Parramatta, N.S.W. 2150 – AUSTRALIA`,
          phone: '+61 2 9893 9255 ',
          website: 'http://rbs-tops.com/',
          logo: 'rbs-logo-custom-.png',
          donation: 24747,
        },
        {
          ...new SponsorInfo(),
          id: 2,
          businessName: 'Riverwood Family Chemist',
          address: `296 Belmore Rd <br>
          Riverwood NSW 2210 – AUSTRALIA`,
          phone: '+61 (02) 9153 6411',
          logo: 'riverwoodChemist.png',
          donation: 10000,
        },
        {
          ...new SponsorInfo(),
          id: 3,
          name: 'Paul Duc Hoang Can',
          businessName: 'A Maximum Security Shutters',
          address: `34 Seville Street, Villawood NSW 2163, Australia`,
          phone: '(02) 9755 7488',
          email: 'amss_aust@hotmail.com',
          website: 'www.amsshutter.com.au',
          logo: 'amss.jpg',
          donation: 2000,
        },
        {
          ...new SponsorInfo(),
          id: 4,
          businessName: 'T & L Car Body Repairs Pty Ltd',
          name: 'Mr Tri &Mrs Uyen Nguyen',
          address: `8/76 Hume High Way, Lanvale NSW 2166, Australia`,
          phone: '(02) 9726 6370',
          email: 'tandlrepair@yahoo.com.au',
          logo: 'tlCarBody.jpg',
          donation: 2000,
        },
        {
          ...new SponsorInfo(),
          id: 5,
          businessName: 'Nho Ban Ngheo (NBN)',
          address: `24 Normanby Street, Fairfield NSW 2165`,
          logo: 'nbn.jpg',
          donation: 2000,
        },
        {
          ...new SponsorInfo(),
          id: 6,
          businessName: 'Van Long Machinery P/L',
          address: `Unit 3 - 282 Milperra Road , Revesby NSW 2212`,
          phone: '(02) 9772 3375',
          donation: 2000,
        },
        {
          ...new SponsorInfo(),
          id: 7,
          businessName: 'Lawyer Cung Dinh Loc & Dr Diep Nguyen',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 8,
          businessName: 'Cavallaro Cake',
          address: '1014 Macquarie St, Liverpool NSW 2170',
          phone: '(02) 9601 0822',
          mobile: '0431 940 490',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 9,
          name: 'Karen Le',
          businessName: 'Life Asian Supermarket',
          address:
            '304 Chapel Road South, Bankstown (Shop Thái Kỳ cũ, bên cạnh Châu Pharmacy)',
          phone: '(02) 9708 4468',
          website: 'www.lifeasiansupermarket.com',
          logo: 'lifeSupermarket.jpeg',
          donation: 500,
        },
        {
          ...new SponsorInfo(),
          id: 10,
          businessName: 'Hai Son Shipping & Transport P/L',
          address: '87 Marigold St, Revesby NSW, 2212',
          phone: '(02) 9773 5455',
          donation: 400,
        },
        {
          ...new SponsorInfo(),
          id: 11,
          businessName: 'Vu Security Doors & Gates PTY. LTD',
          address: '6/34 Chadderton St, Cabramatta NSW 2166',
          phone: '0412 657 214',
          donation: 400,
        },
        {
          ...new SponsorInfo(),
          id: 12,
          businessName: 'Haiden & Co',
          donation: 200,
        },
        {
          ...new SponsorInfo(),
          id: 13,
          businessName: 'Individuals',
          individuals: [
            { name: 'Thanh Di Tran', donation: 1000 },
            {
              name: 'Lien Gia Thanh Gia va Chuong trinh Thang tien hon nhan',
              donation: 730,
            },
            { name: 'Nhom ban ve chai', donation: 550 },
            { name: 'Jimmy Nguyen', donation: 500 },
            { name: 'Vincent Dang', donation: 500 },
            { name: 'Lawrence & Family', donation: 500 },
            { name: 'Loc, Lan & Dien', donation: 500 },
            { name: 'AC Thai Hoang Tran', donation: 300 },
            { name: 'Michelle Tran', donation: 200 },
            { name: 'Thuy Linh Vu Tran', donation: 100 },
            { name: 'Hai Hoa', donation: 100 },
            { name: 'Hang Chi', donation: 100 },
            { name: 'Anh Luong', donation: 50 },
            { name: 'Tai Thoai', donation: 50 },
          ],
        },
      ],
    },
    {
      year: 2017,
      sponsors: [
        {
          ...new SponsorInfo(),
          id: 1,
          businessName: 'Realtime Business Solutions',
          address: `Level 5, 85 George Street<br>
          Parramatta, N.S.W. 2150 – AUSTRALIA`,
          phone: '+61 2 9893 9255 ',
          website: 'http://rbs-tops.com/',
          logo: 'rbs-logo-custom-.png',
          donation: 10000,
        },
        {
          ...new SponsorInfo(),
          id: 2,
          name: 'Paul Duc Hoang Can',
          businessName: 'A Maximum Security Shutters',
          address: `34 Seville Street, Villawood NSW 2163, Australia`,
          phone: '(02) 9755 7488',
          email: 'amss_aust@hotmail.com',
          website: 'www.amsshutter.com.au',
          logo: 'amss.jpg',
          donation: 5000,
        },
        {
          ...new SponsorInfo(),
          id: 3,
          businessName: 'Van Long Machinery P/L',
          address: `Unit 3 - 282 Milperra Road , Revesby NSW 2212`,
          phone: '(02) 9772 3375',
          donation: 3000,
        },
        {
          ...new SponsorInfo(),
          id: 4,
          businessName: 'T & L Car Body Repairs Pty Ltd',
          name: 'Mr Tri &Mrs Uyen Nguyen',
          address: `8/76 Hume High Way, Lanvale NSW 2166, Australia`,
          phone: '(02) 9726 6370',
          email: 'tandlrepair@yahoo.com.au',
          logo: 'tlCarBody.jpg',
          donation: 1500,
        },
        {
          ...new SponsorInfo(),
          id: 5,
          businessName: 'Hai Son Shipping & Transport P/L',
          address: '87 Marigold St, Revesby NSW, 2212',
          phone: '(02) 9773 5455',
          donation: 1000,
        },

        {
          ...new SponsorInfo(),
          id: 6,
          businessName: 'Cha Lua Xinh Xinh',
          address: '49 Helen Street, Sefton NSW 2162',
          phone: '(02) 9743 9990',
          mobile: '0431 940 490',
          donation: 500,
        },
      ],
    },
    {
      year: 2016,
      sponsors: [
        {
          ...new SponsorInfo(),
          id: 1,
          businessName: 'Nho Ban Ngheo (NBN)',
          address: `24 Normanby Street, Fairfield NSW 2165`,
          logo: 'nbn.jpg',
          donation: 5000,
        },
        {
          ...new SponsorInfo(),
          id: 2,
          businessName: 'Van Long Machinery P/L',
          address: `Unit 3 - 282 Milperra Road , Revesby NSW 2212`,
          phone: '(02) 9772 3375',
          donation: 2500,
        },
        {
          ...new SponsorInfo(),
          id: 3,
          businessName: 'T & L Car Body Repairs Pty Ltd',
          name: 'Mr Tri &Mrs Uyen Nguyen',
          address: `8/76 Hume High Way, Lanvale NSW 2166, Australia`,
          phone: '(02) 9726 6370',
          email: 'tandlrepair@yahoo.com.au',
          logo: 'tlCarBody.jpg',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 4,
          businessName: 'Security Door & Gates',
          address: '48 Chadderton Street Cabramatta NSW 2166',
          phone: '(02) 9823 8089',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 5,
          businessName: 'Cha Lua Xinh Xinh',
          address: '49 Helen Street, Sefton NSW 2162',
          phone: '(02) 9743 9990',
          mobile: '0431 940 490',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 6,
          name: 'Paul Duc Hoang Can',
          businessName: 'AMSS Window Roller Shutters',
          address: `34 Seville Street, Villawood NSW 2163, Australia`,
          phone: '(02) 9755 7488',
          email: 'amss_aust@hotmail.com',
          website: 'www.amsshutter.com.au',
          logo: 'amss.jpg',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 7,
          businessName: 'Bun Bo Hue Gia Hoi',
          address: `Canley Heights:   230 Canley Vale Road, Canley Heights<br/>
                           (02) 9723 7324<br/>
          Bankstown:        299 Chapel Road, South Bankstown<br/>
                           (02) 9796 3388<br/>
          Haymarket:        711 George St, Sydney<br/>
                           (02) 9211 0221`,
          logo: 'giaHoi.jpg',
          donation: 500,
        },
        {
          ...new SponsorInfo(),
          id: 8,
          name: 'Karen Le',
          businessName: 'Life Asian Supermarket',
          address:
            '304 Chapel Road South, Bankstown (Shop Thái Kỳ cũ, bên cạnh Châu Pharmacy)',
          phone: '(02) 9708 4468',
          website: 'www.lifeasiansupermarket.com',
          logo: 'lifeSupermarket.jpeg',
          donation: 500,
        },

        {
          ...new SponsorInfo(),
          id: 9,
          name: 'John Nguyen Quoc Sy and Anh Tran',
          businessName: 'South West Immigration Specialist Services',
          address: `8 Buckley Close Fairfield West NSW 2165`,
          phone: '0425 282 620',
          donation: 500,
        },

        {
          ...new SponsorInfo(),
          id: 10,
          businessName: 'Individuals',
          individuals: [
            { name: 'Doan Minh Thanh', donation: 'Stage and Costume' },
            { name: 'Fotoclick', donation: 'Vouchers for lucky door prizes' },
            { name: 'VVP', donation: 520 },
            { name: 'AC Tram Loan', donation: 500 },
            { name: 'GD Son Trang', donation: 400 },
            { name: 'James- Micheal Huong Xua', donation: 200 },
            { name: 'A.C Chau Huong & AC Nam', donation: 200 },
            { name: 'Tiffani Tran', donation: 100 },
            { name: 'Huong Pham - Ngan Tran', donation: 100 },
            { name: 'Chi Lai Friends', donation: 100 },
            { name: 'CD Simon Phan', donation: 100 },
            { name: 'Be Tu Quyen', donation: 100 },
            { name: 'Tuan - Phi Long', donation: 50 },
            { name: 'Hoi Co Nhac Hoai Huong', donation: 50 },
            { name: 'Chi Huong', donation: 50 },
            { name: 'Ba Tang - Chi Chinh', donation: 50 },
            { name: 'Anh Thanh', donation: 50 },
            { name: 'Tracie', donation: 10 },
            { name: 'Various Anonymous', donation: 2965 },
          ],
        },
      ],
    },
    {
      year: 2015,
      sponsors: [
        {
          ...new SponsorInfo(),
          id: 1,
          businessName: 'Bun Bo Hue Gia Hoi',
          address: `Canley Heights:   230 Canley Vale Road, Canley Heights<br/>
                           (02) 9723 7324<br/>
          Bankstown:        299 Chapel Road, South Bankstown<br/>
                           (02) 9796 3388<br/>
          Haymarket:        711 George St, Sydney<br/>
                           (02) 9211 0221`,
          logo: 'giaHoi.jpg',
          donation: '$1,000 - Cash and Voucher',
        },
        {
          ...new SponsorInfo(),
          id: 2,
          name: 'Paul Duc Hoang Can',
          businessName: 'AMSS Window Roller Shutters',
          address: `34 Seville Street, Villawood NSW 2163, Australia`,
          phone: '(02) 9755 7488',
          email: 'amss_aust@hotmail.com',
          website: 'www.amsshutter.com.au',
          logo: 'amss.jpg',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 3,
          businessName: 'T & L Car Body Repairs Pty Ltd',
          name: 'Mr Tri &Mrs Uyen Nguyen',
          address: `8/76 Hume High Way, Lanvale NSW 2166, Australia`,
          phone: '(02) 9726 6370',
          email: 'tandlrepair@yahoo.com.au',
          logo: 'tlCarBody.jpg',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 4,
          businessName: 'Van Long Machinery P/L',
          address: `Unit 3 - 282 Milperra Road , Revesby NSW 2212`,
          phone: '(02) 9772 3375',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 5,
          name: 'Karen Le',
          businessName: 'Life Asian Supermarket',
          address:
            '304 Chapel Road South, Bankstown (Shop Thái Kỳ cũ, bên cạnh Châu Pharmacy)',
          phone: '(02) 9708 4468',
          website: 'www.lifeasiansupermarket.com',
          logo: 'lifeSupermarket.jpeg',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 6,
          businessName: 'Nho Ban Ngheo (NBN)',
          address: `24 Normanby Street, Fairfield NSW 2165`,
          logo: 'nbn.jpg',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 7,
          businessName: 'Vietnam Vision',
          address: `PO Box 313, CABRAMATTA NSW 2166`,
          phone: '(02) 9724 1164',
          website: 'www.vietnamvision.org.au',
          email: 'contact@vietnamvision.org.au',
          logo: 'vietnamVision.jpg',
          donation: 800,
        },
        {
          ...new SponsorInfo(),
          id: 8,
          businessName: 'Cha Lua Xinh Xinh',
          address: '49 Helen Street, Sefton NSW 2162',
          phone: '(02) 9743 9990',
          mobile: '0431 940 490',
          donation: 500,
        },
        {
          ...new SponsorInfo(),
          id: 9,
          businessName: 'Individuals',
          individuals: [
            { name: 'Anonymous', donation: 2000 },
            { name: 'Anonymous', donation: 2000 },
            { name: 'Anonymous', donation: 1000 },
            { name: 'Anonymous', donation: 100 },
            { name: 'Anonymous', donation: 100 },
            { name: 'Anonymous', donation: 50 },
            { name: 'Anonymous', donation: 50 },
            { name: 'Anonymous', donation: 50 },
            { name: 'Mr Tuan (Perth)', donation: 300 },
            { name: 'Andrew Tran', donation: 100 },
            { name: 'Viva Pharmacy', donation: 100 },
            { name: 'Bác Tuyết', donation: 100 },
            { name: 'Ông Bà Nguyễn Xuân Tiếp', donation: 100 },
            { name: 'Mrs Gordana', donation: 65 },
            { name: 'Dì Lê', donation: 50 },
            { name: 'Bác Mỹ Anh', donation: 50 },
            { name: 'Miss Hoa (Melbourne)', donation: 50 },
            { name: 'Various Anonymous', donation: 130 },
          ],
        },
      ],
    },
    {
      year: 2014,
      sponsors: [
        {
          ...new SponsorInfo(),
          id: 1,
          name: 'Donal Kha',
          businessName: 'Amyson Pty Ltd',
          address: `29 Elizabeth Street, Wetherill Park NSW 2164`,
          phone: ' 	(02) 9756 0644',
          donation: 5986.5,
        },
        {
          ...new SponsorInfo(),
          id: 2,
          name: 'Paul Duc Hoang Can',
          businessName: 'AMSS Window Roller Shutters',
          address: `34 Seville Street, Villawood NSW 2163, Australia`,
          phone: '(02) 9755 7488',
          email: 'amss_aust@hotmail.com',
          website: 'www.amsshutter.com.au',
          logo: 'amss.jpg',
          donation: 5000,
        },
        {
          ...new SponsorInfo(),
          id: 3,
          businessName: 'Bun Bo Hue Gia Hoi',
          address: `Canley Heights:   230 Canley Vale Road, Canley Heights<br/>
                           (02) 9723 7324<br/>
          Bankstown:        299 Chapel Road, South Bankstown<br/>
                           (02) 9796 3388<br/>
          Haymarket:        711 George St, Sydney<br/>
                           (02) 9211 0221`,
          logo: 'giaHoi.jpg',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 4,
          name: 'Karen Le',
          businessName: 'Life Asian Supermarket',
          address:
            '304 Chapel Road South, Bankstown (Shop Thái Kỳ cũ, bên cạnh Châu Pharmacy)',
          phone: '(02) 9708 4468',
          website: 'www.lifeasiansupermarket.com',
          logo: 'lifeSupermarket.jpeg',
          donation: 1000,
        },

        {
          ...new SponsorInfo(),
          id: 5,
          businessName: 'T & L Car Body Repairs Pty Ltd',
          name: 'Mr Tri &Mrs Uyen Nguyen',
          address: `8/76 Hume High Way, Lanvale NSW 2166, Australia`,
          phone: '(02) 9726 6370',
          email: 'tandlrepair@yahoo.com.au',
          logo: 'tlCarBody.jpg',
          donation: 1000,
        },
        {
          ...new SponsorInfo(),
          id: 6,
          businessName: "Sang's Hot Bread",
          address: `112 Percy Street, Wellington NSW 2820`,
          phone: '(02) 6845 3322',
          donation: 555,
        },
        {
          ...new SponsorInfo(),
          id: 7,
          businessName: 'Liberty Plaza',
          address: `256-278 Chapel Road, Bankstown NSW 2200`,
          donation: 500,
        },
        {
          ...new SponsorInfo(),
          id: 8,
          name: 'Truong Van Quang',

          businessName: 'Nha Quan Van Tho & Mediadvice Pharmacy',
          address: `Crematoria and Cemeteries<br/>
          121 The Crescent Fairfield 2165 <br/>
          63 Hill Road, Lurnea 2170`,
          phone: '(02) 9723 9923<br/>(02) 9607 2114',
          donation: '$500 and $1,000 for special project',
          logo: 'vantho.png',
        },
        {
          ...new SponsorInfo(),
          id: 9,
          businessName: 'Cha Lua Xinh Xinh',
          address: '49 Helen Street, Sefton NSW 2162',
          phone: '(02) 9743 9990',
          mobile: '0431 940 490',
          donation: 500,
        },
        {
          ...new SponsorInfo(),
          id: 10,
          businessName: 'Top Gold Milk',
          address: '522 Illawara Road, Marrickville NSW 2204',
          phone: '0422 023 999 (Peter) or 0411 565 227 (Christine)',
          website: 'www.topgoldmilk.com.au',
          logo: 'topgoldmilk.png',
          donation: 500,
        },
        {
          ...new SponsorInfo(),
          id: 11,
          businessName: 'Medical IT Solution',
          address: 'Shop 1, 12 West Street, Croydon NSW 2132',
          phone: '(02) 9799 1234',
          donation: 500,
        },
        {
          ...new SponsorInfo(),
          id: 12,
          businessName: 'Van Long Machinery P/L',
          address: `Unit 3 - 282 Milperra Road , Revesby NSW 2212`,
          phone: '(02) 9772 3375',
          donation: 500,
        },
        {
          ...new SponsorInfo(),
          id: 13,
          businessName: 'Tran & Son Hot Bread',
          address: '205 The Boulevarde, Fairfield Heights NSW 2165',
          phone: '(02) 9724 0524 or 0411 228 528',
          donation: 300,
        },
        {
          ...new SponsorInfo(),
          id: 14,
          businessName: 'Huong Xua',
          address: '4/219 Canley Vale Rd, Canley Vale NSW 2166',
          phone: '(02) 9755 0388',
          logo: 'huongXua.jpg',
        },

        {
          ...new SponsorInfo(),
          id: 15,
          businessName: 'Individuals',
          individuals: [
            { name: 'Anonymous', donation: 2000 },
            { name: 'Anonymous', donation: 2000 },
            { name: 'Anonymous', donation: 1000 },
            { name: 'Anonymous', donation: 100 },
            { name: 'Anonymous', donation: 100 },
            { name: 'Anonymous', donation: 50 },
            { name: 'Anonymous', donation: 50 },
            { name: 'Anonymous', donation: 50 },
            { name: 'Mr Tuan (Perth)', donation: 300 },
            { name: 'Andrew Tran', donation: 100 },
            { name: 'Viva Pharmacy', donation: 100 },
            { name: 'Bác Tuyết', donation: 100 },
            { name: 'Ông Bà Nguyễn Xuân Tiếp', donation: 100 },
            { name: 'Mrs Gordana', donation: 65 },
            { name: 'Dì Lê', donation: 50 },
            { name: 'Bác Mỹ Anh', donation: 50 },
            { name: 'Miss Hoa (Melbourne)', donation: 50 },
            { name: 'Various Anonymous', donation: 130 },
          ],
        },
      ],
    },
  ];
}
