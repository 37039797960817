import { ProjectData, ProjectInfo } from 'src/app/models/project.model';

export function getProjectData(): ProjectData[] {
  return [
    {
      projectType: 'Orphan',
      projects: [
        {
          ...new ProjectInfo(),
          id: 1,
          translationKey: 'orphanBurma',
          title: true,
          summary: true,
          correspondence: true,
          images: [`Burma_681x124.png`],
        },
        {
          ...new ProjectInfo(),
          id: 2,
          translationKey: 'orphanBaoLoc',
          title: true,
          correspondence: true,
          images: [
            `20150819_110509_resized.jpg`,
            `20150819_110549.jpg`,
            `CIMG0052.JPG`,
            `CIMG0054.JPG`,
            `CIMG0057.JPG`,
            `CIMG0058.JPG`,
            `CIMG0059.JPG`,
          ],
        },
      ],
    },
    {
      projectType: 'Education',
      projects: [
        {
          ...new ProjectInfo(),
          id: 1,
          translationKey: 'eduKienGiang',
          title: true,
          summary: true,
          correspondence: true,
          images: [
            `kien-giang_709x129.png`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 2,
          translationKey: 'eduYouthPhillippines',
          title: true,
          summary: true,
          correspondence: true,
        },
        {
          ...new ProjectInfo(),
          id: 3,
          translationKey: 'eduEthnic',
          title: true,
          summary: true,
          correspondence: true,
          images: [
            `DSC01958.JPG`,
            `DSC01959.JPG`,
            `DSC01961.JPG`,
            `DSC01964.JPG`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 4,
          translationKey: 'eduKontum',
          title: true,
          correspondence: true,
        },
      ],
    },
    {
      projectType: 'NaturalDisaster',
      projects: [
        {
          ...new ProjectInfo(),
          id: 1,
          translationKey: 'natureTasmanBushfires',
          title: true,
          summary: true,
          correspondence: true,
          images: [
            `redcross_494x90.png`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 2,
          translationKey: 'natureQuangBinhWutip',
          title: true,
          summary: true,
          correspondence: true,
          images: [
            `20131027_162848.jpg`,
            `20131027_170550.jpg`,
            `20131120_192347.jpg`,
            `20131120_193136.jpg`,
            `20131125_083653.jpg`,
            `20131125_084621.jpg`,
            `20131125_100042.jpg`,
            `20131125_100913.jpg`,
            `20131125_101950-Copy.jpg`,
            `20131125_154127.jpg`,
            `20131125_155121.jpg`,
            `DSC_0038.JPG`,
            `DSC_0040.JPG`,
            `DSC03939-1-.JPG`,
            `DSC03998.JPG`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 3,
          translationKey: 'natureTyphoonHaiyan',
          title: true,
          summary: true,
          correspondence: true,
          images: [
            `025558-typhoon-haiyan.jpg`,
            `027125-typhoon-haiyan.jpg`,
            `188228-typhoon-haiyan-fcd02.jpg`,
            `Haiyan.jpg`,
            `MDG-Typhoon-Haiyan-surviv-006.jpg`,
            `typhoon-haiyan.jpg`,
            `Typhoon-Haiyan-1-.jpg`,
            `Victims-of-Typhoon-Haiyan-008.jpg`,
            `VIETNAM-HAIYAN-TYPHOON.jpg`,
            `Caritas_haiyan_filo.jpg`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 4,
          translationKey: 'natureTyphoonYolanda',
          title: true,
          summary: true,
          correspondence: true,
          images: [
            `Churchrestoration_1.jpg`,
            `Churchrestoration_2.jpg`,
            `Churchrestoration_3.jpg`,
            `Churchrestoration_4.jpg`,
            `Churchrestoration_5.jpg`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 5,
          translationKey: 'natureNepalEarthquake',
          title: true,
          correspondence:true,
          images: [
            `File-4-05-2015-3-07-09-pm.png`,
            `File-4-05-2015-3-10-43-pm.jpeg`,
            `File-4-05-2015-3-11-06-pm.jpeg`,
            `File-4-05-2015-3-11-22-pm.jpeg`,
            `File-4-05-2015-3-14-15-pm.jpeg`,
            `File-4-05-2015-3-14-27-pm.jpeg`,
            `File-4-05-2015-3-14-40-pm.jpeg`,
            `File-4-05-2015-3-16-03-pm.jpeg`,
            `Caristas_Nepal-page-001.jpg`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 6,
          translationKey: 'natureQuangNinhFlood',
          title: true,
          summary: true,
          images: [
            `1_nfwb.jpg`,
            `20150731092330-1.jpg`,
            `20150731094311-1.jpg`,
            `20150813135549-flood-qninh.jpg`,
            `floodwaters-ravage-quang-ninh-province-in-a-recent-days-long-downpour-photo-sggp-832363-images239538-coal-500x281.jpg`,
            `hziIIlbT.jpg`,
            `QuangN.jpg`,
            `QuangN1.jpg`,
            `QuangN2.jpg`,
            `QuangN3.jpg`,
            `QuangN4.jpg`,
            `QuangN5.jpg`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 7,
          translationKey: 'natureFijiTyphoon',
          title: true,
          images: [
            `fiji.jpg`,
            `Fiji1.jpg`,
            `Fiji2.jpg`,
            `Fiji3.jpg`,
            `Fiji4.jpg`,
            `Fiji5.jpg`,
            `fiji6.jpg`,
            `Fiji7.jpg`,
            `Fiji8.jpg`,
            `Fiji9.jpg`,
            `receipt_Fiji-page-001.jpg`,
          ],
        },
      ],
    },
    {
      projectType: 'Humantarian',
      projects: [
        {
          ...new ProjectInfo(),
          id: 1,
          translationKey: 'humanBoatPeopleSiemReap',
          title: true,
          summary: true,
          correspondence: true,
          images: [
            `Photo-26-12-2013-3-31-03-pm.jpg`,
            `Photo-26-12-2013-3-32-18-pm.jpg`,
            `Photo-26-12-2013-3-37-02-pm.jpg`,
            `Photo-26-12-2013-3-37-17-pm.jpg`,
            `Photo-26-12-2013-3-37-57-pm.jpg`,
            `Photo-26-12-2013-3-38-10-pm.jpg`,
            `Photo-26-12-2013-3-38-33-pm.jpg`,
            `Photo-26-12-2013-3-38-33-pm-1-.jpg`,
            `Photo-26-12-2013-3-38-50-pm.jpg`,
            `Photo-26-12-2013-3-39-02-pm.jpg`,
            `2013-Certificate_VABAA-Normandale.jpg`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 2,
          translationKey: 'humanFloatingChurch',
          title: true,
          summary: true,
          correspondence: true,
        },
        {
          ...new ProjectInfo(),
          id: 3,
          translationKey: 'humanVietnameseVictimsThailand',
          title: true,
          summary: true,
          correspondence: true,
          images: [
            `IMG_0289.JPG`,
            `IMG_0294.JPG`,
            `IMG_0298.JPG`,
            `IMG_0320.JPG`,
            `IMG_5549.JPG`,
            `IMG_5552.JPG`,
            `IMG_5557.JPG`,
            `IMG_5576.JPG`,
            `IMG_5578.JPG`,
            `IMG_5584.JPG`,
            `IMG_5590.JPG`,
            `IMG_5591.JPG`,
            `IMG_5600.JPG`,
            `IMG_5617.JPG`,
            `IMG_5626.JPG`,
            `IMG_5632.JPG`,
            `IMG_5645.JPG`,
            `IMG_5650.JPG`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 4,
          translationKey: 'humanDisabledChildrenThatKhe',
          title: true,
          summary: true,
          images: [
            `13499_919857848071433_5624757786444903373_n.jpg`,
            `14090_919857984738086_9025822759406555617_n.jpg`,
            `17401_919857738071444_3544594538492077023_n.jpg`,
            `1470400_919858228071395_2634643395545191984_n.jpg`,
            `10980739_919858158071402_4167039120948458860_n.jpg`,
            `10984598_919858084738076_3190485732951303635_n.jpg`,
            `11013381_919858124738072_8024379609778712830_n.jpg`,
            `11039247_919857714738113_1621289929010764341_n.jpg`,
            `11058526_919857728071445_3123295771268798176_n.jpg`,
            `11140344_919858174738067_5391840221353953098_n.jpg`,
            `11147872_919857711404780_6534481261444797077_n.jpg`,
            `11224536_919858238071394_5022719158207568853_n.jpg`,
            `11255369_919858061404745_2114003856632537436_n.jpg`,
            `11264013_919858064738078_4548774320188841468_n.jpg`,
            `11264919_919857858071432_5462331968551105072_n.jpg`,
            `11267754_919857831404768_2672156606381982725_n.jpg`,
            `11269467_919858204738064_5691151470879804588_n.jpg`,
            `11295673_919857834738101_2641812365688837229_n.jpg`,
            `11295681_919858041404747_2767626987177511100_n.jpg`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 5,
          translationKey: 'humanChickenPoultryKenya',
          title: true,
          summary: true,
          images: [
            `1538930_935334563190428_3422736257139169584_n.jpg`,
            `10665189_920265708030647_1881065046375140752_n.jpg`,
            `11011281_935334309857120_7271648068122401096_n.jpg`,
            `11012083_920265648030653_4162078560420253845_n.jpg`,
            `11057724_920265621363989_1582578239846493113_n.jpg`,
            `11142427_920265638030654_6104231062975247222_n.jpg`,
            `11146481_920265501364001_3917819824332786362_n.jpg`,
            `11164057_920265511364000_9197036082404308593_n.jpg`,
            `11227557_920265488030669_5459351573745379626_n.jpg`,
            `11244403_920265728030645_8052216835008767307_n.jpg`,
            `11264886_920265721363979_6328222052432313023_n.jpg`,
            `11295589_920265531363998_3373769900075615338_n.jpg`,
            `11329950_920265508030667_6824349773114173011_n.jpg`,
            `11350714_920265634697321_5649306974788962759_n.jpg`,
            `11401428_935334403190444_6663353654680464760_n.jpg`,
            `11406924_935334466523771_865182864689830889_n.jpg`,
            `11535848_935334513190433_7714836406789026362_n.jpg`,
          ],
          title1: true,
          images1: [
            `1.jpg`,
            `2.jpg`,
            `3.jpg`,
            `4.jpg`,
            `5.jpg`,
            `6.jpg`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 6,
          translationKey: 'humanTreePlantingNamDinh',
          title: true,
          correspondence: true,
        },
        {
          ...new ProjectInfo(),
          id: 7,
          translationKey: 'humanCleanWaterThanhHoa',
          title: true,
          correspondence: true,
          images: [
            `File_000.jpeg`,
            `File_002.jpeg`,
            `File_003.jpeg`,
            `File_004.jpeg`,
            `File_005.jpeg`,
            `File_006.jpeg`,
            `Receipt.jpg`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 8,
          translationKey: 'humanCleanWaterTrangDen',
          title: true,
          images: [
            `1.jpg`,
            `2.jpg`,
            `3.jpg`,
            `4.jpg`,
            `5.jpg`,
            `6.jpg`,
            `IMG_6563.JPG`,
            `IMG_6564.JPG`,
            `IMG_6565.JPG`,
            `IMG_6565_1.JPG`,
            `IMG_6566.JPG`,
            `IMG_6567.JPG`,
            `IMG_6568.JPG`,
            `IMG_6570.JPG`,
            `IMG_6571.JPG`,
          ],
        },
      ],
    },
    {
      projectType: 'Health',
      projects: [
        {
          ...new ProjectInfo(),
          id: 1,
          translationKey: 'healthKontumMedical',
          title: true,
          summary: true,
          images: [
            `Kontum-1.jpg`,
            `Kontum-2.jpg`,
            `Kontum-3.jpg`,
            `kontum-4.png`,
            `Kontum-5.jpg`,
            `kontum-6.jpg`,
            `Kontum-7.jpg`,
            `Kontum-8.jpg`,
            `Kontum-9.jpg`,
            `Kontum-10.jpg`,
            `Kontum-11.jpg`,
            `Kontum-12.jpg`,
          ],
        },
        {
          ...new ProjectInfo(),
          id: 2,
          translationKey: 'healthTamNganMedical',
          title: true,
          images: [
            `hinh 2.jpg`,
            `hinh.jpg`,
            `image1 (1).JPG`,
            `image1.JPG`,
            `image2.JPG`,
            `image3 (1).JPG`,
            `image3.JPG`,
            `image5.JPG`,
            `thumbnail (1).jpg`,
            `thumbnail.jpg`,
          ],
        },
      ],
    },
  ];
}
