<div style="clear: both; margin-top: 50px; justify-content: center">
  <div style="clear: both">
    <h1 *ngIf="sponsorInfo.individuals.length === 0" [innerHTML]="sponsorInfo.businessName"></h1>
    <h1 *ngIf="sponsorInfo.individuals.length > 0" translate>sponsor.individual</h1>
  </div>
  <div style="clear: both">
    <div style="overflow-x: auto">
      <table *ngIf="sponsorInfo.individuals.length === 0">
        <tr>
          <td style="vertical-align: top;">
            <img [src]="logo" onerror="this.src='assets/images/sponsors/blank.png'"/>
          </td>
          <td style="vertical-align: top;">
            <table>
              <tr *ngIf="sponsorInfo.name !== ''">
                <td class="fieldName" translate>sponsor.name</td>
                <td class="fieldValue" [innerHTML]="sponsorInfo.name"></td>
              </tr>
              <tr *ngIf="sponsorInfo.businessName !== ''">
                <td class="fieldName" translate>sponsor.company</td>
                <td class="fieldValue" [innerHTML]="sponsorInfo.businessName"></td>
              </tr>
              <tr *ngIf="sponsorInfo.address !== ''">
                <td class="fieldName" translate>sponsor.address</td>
                <td class="fieldValue" [innerHTML]="sponsorInfo.address"></td>
              </tr>
              <tr *ngIf="sponsorInfo.phone !== ''">
                <td class="fieldName" translate>sponsor.phone</td>
                <td class="fieldValue" [innerHTML]="sponsorInfo.phone"></td>
              </tr>
              <tr *ngIf="sponsorInfo.mobile !== ''">
                <td class="fieldName" translate>sponsor.mobile</td>
                <td class="fieldValue" [innerHTML]="sponsorInfo.mobile"></td>
              </tr>
              <tr *ngIf="sponsorInfo.email !== ''">
                <td class="fieldName" translate>sponsor.email</td>
                <td class="fieldValue" [innerHTML]="sponsorInfo.email"></td>
              </tr>
              <tr *ngIf="sponsorInfo.website !== ''">
                <td class="fieldName" translate>sponsor.website</td>
                <td class="fieldValue" [innerHTML]="sponsorInfo.website"></td>
              </tr>
              <tr *ngIf="sponsorInfo.donation !== 0">
                <td class="fieldName" translate>sponsor.donation</td>
                <td class="fieldValue" [innerHTML]="sponsorInfo.donation | money"></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <table *ngIf="sponsorInfo.individuals.length > 0">
        <tr>
          <td class="fieldName" translate>sponsor.name</td>
          <td class="fieldName" translate>sponsor.donation</td>
        </tr>
        <tr *ngFor="let individual of sponsorInfo.individuals">
          <td class="fieldName" [innerHTML]="individual.name"></td>
          <td class="fieldValue" [innerHTML]="individual.donation | money"></td>
        </tr>
      </table>
    </div>
  </div>
</div>
