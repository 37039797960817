import { CommitteeData } from 'src/app/models/committee.model';

export function getCommitteeInfo(): CommitteeData[] {
  return [
    {
      year: "current",
    },
    {
      year: "2014-2016",
    },
    {
      year: "2012-2014",
    }
  ]
}
