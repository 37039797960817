import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { MenuModel } from 'src/app/models/menu.model';
import { Navigate } from '@ngxs/router-plugin';
import { HttpClient } from '@angular/common/http';
import { UpdateSponsorContext } from 'src/app/states/sponsor.state';
import { getSponsorData } from 'src/app/components/sponsor/sponsor-data';
import { getOfficeBearerInfo } from '../who-we-are/office-bearers/office-bearers.data';
import { UpdateOfficeBearerContext } from 'src/app/states/officeBearers.state';
import { UpdateCommitteeContext } from 'src/app/states/committee.state';
import { ProjectInfo, ProjectType } from 'src/app/models/project.model';
import { UpdateProjectContext } from 'src/app/states/project.state';
import { getProjectData } from '../project/project.data';
import { UpdateActivityContext } from 'src/app/states/activity.state';
import { getActivityData } from '../activity/activity.data';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnChanges {
  @Input() inputMenuData: MenuModel[] = [];

  menuData: MenuModel[] = [];

  constructor(
    private store: Store,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.menuData = this.inputMenuData;
  }

  openActivity(year: number, id: number) {
    const activityInfo = getActivityData()
      .filter((x) => x.year === year)[0]
      ?.activities.filter((x) => x.id === id)[0];
    this.store.dispatch(
      new UpdateActivityContext({
        year: year,
        activityInfo: activityInfo,
      })
    );
    this.store.dispatch(new Navigate(['activity']));
  }

  openSponsor(year: number, id: number) {
    const sponsorInfo = getSponsorData()
      .filter((x) => x.year === year)[0]
      ?.sponsors.filter((x) => x.id === id)[0];
    this.store.dispatch(new UpdateSponsorContext(sponsorInfo));
    this.store.dispatch(new Navigate(['sponsor']));
  }

  openOfficeBearers(year: string, id: number) {
    let officeBearers = getOfficeBearerInfo().filter((x) => x.year === year)[0]
      ?.officeBearers;
    if (id !== 0) officeBearers = officeBearers.filter((x) => x.id === id);

    this.store.dispatch(
      new UpdateOfficeBearerContext({
        year: year,
        officeBearers: officeBearers,
      })
    );
    this.store.dispatch(new Navigate(['office-bearers']));
  }

  openCommittee(year: string) {
    this.store.dispatch(new UpdateCommitteeContext({ year: year }));
    this.store.dispatch(new Navigate(['committee']));
  }

  openProject(projectType: ProjectType, id: number) {
    const projectInfo =
      getProjectData()
        .find((x) => x.projectType === projectType)
        ?.projects.find((x) => x.id === id) ?? new ProjectInfo();

    this.store.dispatch(new UpdateProjectContext(projectInfo));
    this.store.dispatch(new Navigate(['project']));
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  navigateTo(page?: string) {
    if (page) this.store.dispatch(new Navigate([page]));
  }
}
