import { Pipe, PipeTransform } from '@angular/core';
import { getMoneyString } from 'src/app/utils/number';

@Pipe({ name: 'money' })
export class MoneyPipe implements PipeTransform {
  transform(number: number | string) {
    if (isNaN(Number(number))) return number;
    return getMoneyString(Number(number));
  }
}
