<app-header></app-header>

<app-menu [inputMenuData]="menuData"></app-menu>

<div style="position: relative">
  <div style="position: absolute; top: 0; left: 0; width: 100vw; z-index: -1">
    <app-background-image-slider></app-background-image-slider>
  </div>

  <div>
    <div
      style="
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <div style="width: 80vw; min-height: 600px;">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div
    style="
      width: 100vw;
      height: 9px;
      background-image: url('assets/images/sha6.png');
    "
  ></div>
  <app-footer></app-footer>
</div>
