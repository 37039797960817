import { OfficeBearerData } from 'src/app/models/officeBearer.model';

export function getOfficeBearerInfo(): OfficeBearerData[] {
  return [
    {
      year: "current",
      officeBearers: [
        {
          id: 1,
          name: 'officeBearer.person.nat.name',
          role: `officeBearer.role.president`,
          biography: 'officeBearer.person.nat.bio',
          photo: 'nat.jpg',
        },
        {
          id: 2,
          name: 'officeBearer.person.ivy.name',
          role: `officeBearer.role.vicePresident`,
          biography: 'officeBearer.person.ivy.bio',
          photo: 'ivy.jpg',
        },
        {
          id: 3,
          name: 'officeBearer.person.van.name',
          role: `officeBearer.role.secretary`,
          biography: 'officeBearer.person.van.bio',
          photo: 'van.jpg',
        },
        {
          id: 4,
          name: 'officeBearer.person.quynh.name',
          role: `officeBearer.role.treasurer`,
          biography: 'officeBearer.person.quynh.bio',
          photo: 'quynh.jpg',
        },
      ],
    },
    {
      year: "2014-2016",
      officeBearers: [
        {
          id: 1,
          name: 'officeBearer.person.ivy.name',
          role: `officeBearer.role.president`,
          biography: 'officeBearer.person.ivy.bio',
          photo: 'ivy.jpg',
        },
        {
          id: 2,
          name: 'officeBearer.person.thanh.name',
          role: `officeBearer.role.viceMarkettingCommunication`,
          biography: 'officeBearer.person.thanh.bio',
          photo: 'thanh.jpg',
        },
        {
          id: 3,
          name: 'officeBearer.person.quynh.name',
          role: `officeBearer.role.viceFinanceOperation`,
          biography: 'officeBearer.person.quynh.bio',
          photo: 'quynh.jpg',
        },
        {
          id: 4,
          name: 'officeBearer.person.tung.name',
          role: `officeBearer.role.mediator`,
          biography: 'officeBearer.person.tung.bio',
          photo: 'tung.jpg',
        },
        {
          id: 5,
          name: 'officeBearer.person.nat.name',
          role: `officeBearer.role.secretary`,
          biography: 'officeBearer.person.nat.bio',
          photo: 'nat.jpg',
        },
        {
          id: 6,
          name: 'officeBearer.person.michael.name',
          role: `officeBearer.role.treasurer`,
          biography: 'officeBearer.person.michael.bio',
          photo: 'michael.jpg',
        },
        {
          id: 7,
          name: 'officeBearer.person.amanda.name',
          role: `officeBearer.role.publicOfficer`,
          biography: 'officeBearer.person.amanda.bio',
          photo: 'amanda.jpg',
        },
        {
          id: 8,
          name: 'officeBearer.person.ngan.name',
          role: `officeBearer.role.admin`,
          biography: 'officeBearer.person.ngan.bio',
          photo: 'ngan.jpg',
        },
        {
          id: 9,
          name: 'officeBearer.person.liem.name',
          role: `officeBearer.role.spirit`,
          biography: 'officeBearer.person.liem.bio',
          photo: 'liem.jpg',
        },
      ],
    },
    {
      year: "2012-2014",
      officeBearers: [
        {
          id: 1,
          name: 'officeBearer.person.ngan.name',
          role: `officeBearer.role.president`,
          biography: 'officeBearer.person.ngan.bio',
          photo: 'ngan.jpg',
        },
        {
          id: 2,
          name: 'officeBearer.person.thanh.name',
          role: `officeBearer.role.viceMarkettingOperation`,
          biography: 'officeBearer.person.thanh.bio',
          photo: 'thanh.jpg',
        },
        {
          id: 3,
          name: 'officeBearer.person.ivy.name',
          role: `officeBearer.role.viceGovernanceCommunication`,
          biography: 'officeBearer.person.ivy.bio',
          photo: 'ivy.jpg',
        },
        {
          id: 4,
          name: 'officeBearer.person.tung.name',
          role: `officeBearer.role.mediator`,
          biography: 'officeBearer.person.tung.bio',
          photo: 'tung.jpg',
        },
        {
          id: 5,
          name: 'officeBearer.person.thuong.name',
          role: `officeBearer.role.secretary`,
          biography: 'officeBearer.person.thuong.bio',
          photo: 'thuong.jpg',
        },
        {
          id: 6,
          name: 'officeBearer.person.yen.name',
          role: `officeBearer.role.treasurer`,
          biography: 'officeBearer.person.yen.bio',
          photo: 'yen.jpg',
        },
        {
          id: 7,
          name: 'officeBearer.person.liem.name',
          role: `officeBearer.role.priest`,
          biography: 'officeBearer.person.liem.bio',
          photo: 'liem.jpg',
        },
      ],
    },
  ];
}
