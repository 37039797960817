<h1
  *ngIf="projectInfo.title"
  [innerHTML]="'project.projects.' + projectInfo.translationKey + '.title' | translate"
></h1>
<h2
  *ngIf="projectInfo.summary"
  [innerHTML]="'project.summary' | translate"
></h2>
<span
  *ngIf="projectInfo.summary"
  [innerHTML]="'project.projects.' + projectInfo.translationKey + '.summary' | translate"
></span>
<div>
  <p class="imgContainer" gallerize>
    <img *ngFor="let img of projectInfo.images" [src]="'assets/images/projects/' + projectInfo.translationKey + '/' + img" />
  </p>
</div>
<h2 *ngIf="projectInfo.title1" [innerHTML]="'project.projects.' + projectInfo.translationKey + '.title1' | translate"></h2>
<div>
  <p class="imgContainer" gallerize>
    <img *ngFor="let img of projectInfo.images1" [src]="'assets/images/projects/' + projectInfo.translationKey + '/' + img" />
  </p>
</div>
<h2
  *ngIf="projectInfo.correspondence"
  [innerHTML]="'project.correspondence' | translate"
></h2>
<div
  *ngIf="projectInfo.correspondence"
  class="correspondence"
  [innerHTML]="'project.projects.' + projectInfo.translationKey + '.correspondence' | translate"
></div>
