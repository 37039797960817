export interface SponsorData {
  year: number;
  sponsors: SponsorInfo[];
}

export class SponsorInfo {
  id = 0;
  businessName = "";
  name = "";
  address = "";
  phone = "";
  mobile = "";
  email = "";
  website = "";
  logo = "";
  donation: number | string = 0;
  individuals: Individual[] = [];
}

export class Individual {
  name = "";
  donation: number | string = 0;
}
