import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  activeLang = 'en';

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  useLanguage(lang: string) {
    this.translate.use(lang);
    this.activeLang = lang;
  }
}
