<div style="margin-top: 30px">
  <table>
    <tbody>
      <ng-container *ngFor="let officeBearer of officeBearers">
        <tr>
          <td width="187" valign="top">
            <img
              [src]="
                'assets/images/office-bearers/' +
                year +
                '/' +
                officeBearer.photo
              "
              class="portrait"
            />
          </td>
          <td width="699" valign="middle">
            <h2 translate>{{ officeBearer.name }}</h2>
            <i translate>{{ officeBearer.role }}</i>
            <p align="justify"></p>
            <span [innerHTML]="officeBearer.biography | translate"></span>
            <p></p>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div class="split-line"></div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
