import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { SponsorInfo } from 'src/app/models/sponsor.model';
import { SponsorState } from 'src/app/states/sponsor.state';
import { getMoneyString } from 'src/app/utils/number';
import { getSponsorData } from 'src/app/components/sponsor/sponsor-data';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss'],
})
export class SponsorComponent implements OnInit, OnDestroy {
  sponsorSubscription: Subscription;

  sponsorInfo = new SponsorInfo();
  logo = ''

  constructor(private store: Store) {
    this.sponsorSubscription = this.store
      .select(SponsorState.getState)
      .subscribe((sponsorInfo) => {
        if (sponsorInfo) {
          this.sponsorInfo = sponsorInfo;
          this.logo = `assets/images/sponsors/${sponsorInfo.logo}`;
        } else
          throw new Error(`SponsorComponent: invalid sponsor subscription`);
      });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.sponsorSubscription?.unsubscribe();
  }
}
