export interface ProjectData {
  projectType: ProjectType;
  projects: ProjectInfo[];
}

export class ProjectInfo {
  id = 0;
  translationKey?: string;
  title = false;
  summary = false;
  images: string[] = [];
  correspondence = false;
  title1 = false;
  images1: string[] = [];
}

export type ProjectType = 'Orphan' | 'Education' | 'NaturalDisaster' | 'Humantarian' | 'Health'
